// src/App.js
import './App.css';
import Home from './pages/Home';
import Ratings from './pages/Ratings'; // Un solo componente para todas las áreas
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  },
  {
    path: "ratings/:areaId",
    element: <Ratings />,
  },
]);

const App = () => {
  return (
    <RouterProvider router={router} />
  );
};

export default App;
