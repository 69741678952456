import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
import LOGO from './img/logo_fp.png';
import './Ratings.css';
import { FaSmile, FaMeh, FaFrown, FaGrinBeam, FaAngry } from 'react-icons/fa';
import HomeButton from './HomeButton';

const Ratings = () => {
  const { areaId } = useParams(); // Captura el área dinámica desde la URL

  // Estado para las evaluaciones de cada categoría
  const [ratings, setRatings] = useState({
    servicio: 0,
    comida: 0,
    limpieza: 0,
  });

  const name = "Anonimo";
  const areaNames = {
    CMD: 'Comedor',
    CLR: 'Cliente Recolecta',
    RCP: 'Recepción',
    WC: 'Sanitarios'
  };

  // Función para manejar el clic en los emojis de cada categoría
  const handleEmojiClick = async (category, selectedRating) => {
    setRatings(prevRatings => ({
      ...prevRatings,
      [category]: selectedRating,
    }));

    const data = {
        nameuser: name,
        idArea: areaId,
        idEvaluationType: getEvaluationTypeId(category), // Asigna el tipo de evaluación (Servicio, Comida, Limpieza)
        idRating: selectedRating, // Asigna la calificación seleccionada (el emoji)
        feedback: "Sin comentarios" // Comentario por defecto
    };

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}`, data);
      Swal.fire({
        icon: 'success',
        title: 'Éxito',
        text: `Evaluación de ${category} enviada correctamente.`,
      });

      setRatings(prevRatings => ({
        ...prevRatings,
        [category]: 0, // Reinicia el rating para esa categoría después de enviar la evaluación
      }));
    } catch (error) {
      console.error('Error al enviar la evaluación:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Ocurrió un error al enviar la evaluación de ${category}.`,
      });
    }
  };

  // Asigna el ID del tipo de evaluación según la categoría
  const getEvaluationTypeId = (category) => {
    const types = {
      servicio: 1, // Id de "Servicio"
      comida: 2,   // Id de "Comida"
      limpieza: 3, // Id de "Limpieza"
    };
    return types[category];
  };

  const renderEmojiSet = (category) => (
      <div className="emoji-container">
        <h3>Evalúa {category.charAt(0).toUpperCase() + category.slice(1)}</h3>
        <FaGrinBeam
            className={`emoji ${ratings[category] === 1 ? 'selected' : ''}`}
            onClick={() => handleEmojiClick(category, 1)}
        />
        <FaSmile
            className={`emoji ${ratings[category] === 2 ? 'selected' : ''}`}
            onClick={() => handleEmojiClick(category, 2)}
        />
        <FaMeh
            className={`emoji ${ratings[category] === 3 ? 'selected' : ''}`}
            onClick={() => handleEmojiClick(category, 3)}
        />
        <FaFrown
            className={`emoji ${ratings[category] === 4 ? 'selected' : ''}`}
            onClick={() => handleEmojiClick(category, 4)}
        />
        <FaAngry
            className={`emoji ${ratings[category] === 5 ? 'selected' : ''}`}
            onClick={() => handleEmojiClick(category, 5)}
        />
      </div>
  );

  return (
      <div className="container">
        <img src={LOGO} alt="Logo de la empresa" className="logo" />
        <h1>Evaluación de {areaNames[areaId] || 'Área No Disponible'}</h1>

        {/* Solo para el área de comedor se muestran las tres evaluaciones */}
        {areaId === 'CMD' ? (
            <>
              {renderEmojiSet('servicio')}
              {renderEmojiSet('comida')}
              {renderEmojiSet('limpieza')}
            </>
        ) : (
            renderEmojiSet('servicio') // Para las otras áreas, solo se evalúa el servicio
        )}

        <HomeButton />
      </div>
  );
};

export default Ratings;
