// src/Home.js
import React from 'react';
import LOGO from './img/logo_fp.png'
import './Ratings.css'
import './Home.css'
import { MdBrunchDining } from "react-icons/md";
import { FaUserCheck } from "react-icons/fa";
import { MdOutlineLiving } from "react-icons/md";
import { GrRestroom } from "react-icons/gr";

const Home = () => {

  return (
   <div className='container'>
      <img src={LOGO} alt="Logo de la empresa" className="logo" />

      <h1>Bienvenido</h1>

       <div className='button-container'>
           <a href='/ratings/CMD'>
               <button className="btn" id='button-Cmd'>Comedor <MdBrunchDining/></button>
           </a>
           <a href='/ratings/CLR'>
               <button className="btn" id='button-Clt'>Cliente recolecta <FaUserCheck/></button>
           </a>
           <a href='/ratings/RCP'>
               <button className="btn" id='button-Rcp'>Recepción <MdOutlineLiving/></button>
           </a>
           <a href='/ratings/WC'>
               <button className="btn" id='button-Wc'>Sanitarios <GrRestroom/></button>
           </a>
       </div>
   </div>
  );
};

export default Home;
